import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, closeModal, onConfirm, action }) => {
	if (!isOpen) return null;

	const content = action === 'rechazar' ? 'la invitación' : 'tu asistencia';

	return (
		<div className="modal-overlay">
		<div className="modal-content">
			<h2>{action}</h2>
			<p>¿Estás seguro de que deseas {action} {content}?</p>
			<div className="modal-buttons">
			<button className="cancel-button" onClick={closeModal}>Cancelar</button>
			<button className="confirm-button" onClick={onConfirm}>Aceptar</button>
			</div>
		</div>
		</div>
	);
};

export default Modal;

import React from 'react';
import './ItinerarySection.css';
import centerImage from '../assets/images/Separator Top.png';


const ItinerarySection = ({ itineraryTitle, itinerary }) => {
  return (
    <section id="itinerary" className="itinerary-section">
      <img src={centerImage} alt="Flower" className="center-image" />
      <h2>{itineraryTitle}</h2>
      <div className="itinerary-container">
        {itinerary.map((item, index) => (
          <div className="itinerary-item" key={index}>
            <p className="itinerary-time">{item.time}</p>
            <div className="itinerary-divider">
              <span className="divider-line"></span>
              <span className="divider-decoration"></span>
              <span className="divider-line"></span>
            </div>
            <p className="itinerary-description">{item.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ItinerarySection;

// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faClock, faEnvelopeOpenText, faCalendarAlt, faHeart, faBook, faImages, faMapMarkerAlt, faGift } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="mainImageSection" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </li>
        <li>
          <Link to="hero" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </Link>
        </li>
        <li>
          <Link to="events" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </Link>
        </li>
        <li>
          <Link to="itinerary" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faClock} />
          </Link>
        </li>
        <li>
          <Link to="rsvp" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
          </Link>
        </li>
        <li>
          <Link to="gifts" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faGift} />
          </Link>
        </li>
        <li>
          <Link to="couple" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faHeart} />
          </Link>
        </li>
        <li>
          <Link to="story" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faBook} />
          </Link>
        </li>
        <li>
          <Link to="gallery" smooth={true} duration={500}>
            <FontAwesomeIcon icon={faImages} />
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;

import React, { useState } from 'react';
import './RSVPSection.css';
import topImage from '../assets/images/top-left-flower.png';
import bottomLeftImage from '../assets/images/Bride and Groom.png'; // New image for bottom-left
import separatorImage from '../assets/images/Separator.png';
import Modal from './Modal';

const RSVPSection = ({ guestCode, rsvpData, fetchGuestInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [newStatus, setNewStatus] = useState(null);

  const openModal = (action, status) => {
    setAction(action);
    setNewStatus(status);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAction(null);
    setNewStatus(null);
  };

  const handleConfirm = async () => {
    try {
      const response = await fetch('https://l339s2lqvc.execute-api.us-east-2.amazonaws.com/api/updateConfirmation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          guest_code: guestCode || 'default_guest_code',
          confirmation_status: newStatus,
        }),
      });

      if (response.ok) {
        fetchGuestInfo();
      } else {
        alert(`Hubo un error al ${action} tu asistencia. Por favor, intenta de nuevo.`);
      }
    } catch (error) {
      console.error(`Error al ${action} asistencia:`, error);
      alert(`Ocurrió un problema al ${action}. Por favor, intenta de nuevo más tarde.`);
    }

    closeModal();
  };

  return (
    <section className="rsvp-section" id="rsvp">
      <div className="rsvp-content">
        <p>Querido/a:</p>
        <p className="guest-name">{rsvpData.invitationDetails.guestName}</p>
        <img src={separatorImage} alt="Separator" className="separator-image" />
        <p>{rsvpData.invitationText}</p>
        <p>{rsvpData.invitationText2}</p>
        <p className="guest-qty">
          <b>Adultos:</b> {rsvpData.invitationDetails.guestCount}
        </p>
        <p className="dress-code">
          <b>Código de vestimenta:</b>
          <br /> {rsvpData.invitationDetails.dressCode}
        </p>
        <p className="note">{rsvpData.invitationText3}</p>

        {rsvpData.invitationDetails.confirmationStatus === null ? (
          <>
            <button className="unconfirm-button" onClick={() => openModal('rechazar', 0)}>
              No Asistiré
            </button>
            <button className="confirm-button" onClick={() => openModal('confirmar', 1)}>
              Asistiré
            </button>
          </>
        ) : (
          <button
            className={rsvpData.invitationDetails.confirmationStatus === 1 ? 'unconfirm-button' : 'confirm-button'}
            onClick={() =>
              openModal(
                rsvpData.invitationDetails.confirmationStatus === 1 ? 'desconfirmar' : 'confirmar',
                rsvpData.invitationDetails.confirmationStatus === 1 ? 0 : 1
              )
            }
          >
            {rsvpData.invitationDetails.confirmationStatus === 1 ? 'Desconfirmar' : 'Confirmar'}
          </button>
        )}

        <Modal isOpen={isModalOpen} closeModal={closeModal} onConfirm={handleConfirm} action={action} />
        <img src={bottomLeftImage} alt="Bride and Groom" className="bottom-left-image" />
      </div>
    </section>
  );
};

export default RSVPSection;

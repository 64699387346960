import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import MainImageSection from './components/MainImageSection';
import HeroSection from './components/HeroSection';
import CoupleSection from './components/CoupleSection';
import StorySection from './components/StorySection';
import GallerySection from './components/GallerySection';
import RSVPSection from './components/RSVPSection';
import EventsSection from './components/EventsSection';
import ItinerarySection from './components/ItinerarySection';
import GiftTableSection from './components/GiftTableSection';
import Footer from './components/Footer';

// Imports de imágenes
import image1 from './assets/images/story/story1.jpeg';
import image2 from './assets/images/story/story2.jpeg';
import image3 from './assets/images/story/story3.jpeg';
import image4 from './assets/images/ourlove4.jpg';
import image5 from './assets/images/ourlove4.jpg';
import groomImage from './assets/images/couple/groom.jpeg'; 
import brideImage from './assets/images/couple/bride.jpeg'; 
import coupleImage from './assets/images/couple/couple.png';
import imageGallery1 from './assets/images/gallery/1.jpeg';
import imageGallery2 from './assets/images/gallery/2.jpeg';
import imageGallery3 from './assets/images/gallery/3.jpeg';
import imageGallery4 from './assets/images/gallery/4.jpeg';
import imageGallery5 from './assets/images/gallery/5.jpeg';
import imageGallery6 from './assets/images/gallery/6.jpeg';

import closingVideo from './assets/envelope/closing.mp4';
import openingVideo from './assets/envelope/opening.mp4';
import logo from './assets/envelope/Logo.png'; 
import logoGif from './assets/envelope/Logo.gif'; 
import backgroundMusic from './assets/envelope/Background-Music.mp3';


import './App.css';

const pageTransition = {
  hidden: { opacity: 0, scale: 0.95 }, // Start with a slight scale-down
  visible: { 
    opacity: 1, 
    scale: 1, // Scale up to full size
    transition: { duration: 0.8 } // Control the speed of the animation
  },
  exit: { opacity: 0, scale: 0.95 } // Optional exit animation if you need
};

const SectionWrapper = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: false, // Only animate once
    threshold: 0.1,    // When 10% of the element is visible
  });

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      exit="hidden"
      variants={pageTransition}
      style={{ overflow: 'hidden' }}
    >
      {children}
    </motion.div>
  );
};

function App() {
  return (
    <Router>
      <MainPage />
    </Router>
  );
}

const MainPage = () => {
  const location = useLocation(); // Get current location object (URL)
  
  // Parse the query parameters using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const [data, setData] = useState(null);

  const [isEnvelopeOpened, setIsEnvelopeOpened] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const openVideoRef = useRef(null);
  const audioRef = useRef(null);

  // Handle click on the closing video
  const handleEnvelopeClick = () => {
    setIsEnvelopeOpened(true); // Switch to opening video
    if (audioRef.current) {
      audioRef.current.volume = 0.3;
      audioRef.current.play(); // Reproduce la música cuando se hace clic
    }
  };

  // Once the opening video ends, show the page content
  const handleOpenVideoEnd = () => {
    setShowContent(true);
  };

  useEffect(() => {
    // Cuando el componente se monta, configurar el audio para que se mantenga en reproducción
    if (audioRef.current) {
      audioRef.current.volume = 0.3; // Ajusta el volumen si es necesario
    }
  }, []);

  const fetchGuestInfo = async () => {
    try {
      // const guestResponse = await fetch(`https://zc1l59bgfi.execute-api.us-east-2.amazonaws.com/api/getGuestInfo?guest_code=${code}`);
      // const guestData = await guestResponse.json();
      const guestData = {};

      const response = {
        // Datos de la boda, eventos, etc.
        weddingName: "Boda Farias Ibarra",
        weddingMessage: "Acompáñanos a celebrar nuestra historia de amor",
        weddingDate: "2024-12-24T17:00:00",

        coupleSectionTitle: "Nuestra Historia",
        groomName: "William Farias",
        groomDescription: "Nacido en una familia que me inculcó el valor de la honestidad y la perseverancia. Siempre he sentido una profunda conexión con la música, la cual ha sido mi compañera en cada paso de la vida.",
        groomDescription2: "Este día no sería el mismo sin ti. Agradezco de corazón tu presencia y cariño.",
        brideName: "Allison Ibarra",
        brideDescription: "Amante de los pequeños momentos, de las risas espontáneas y las charlas largas con los seres queridos. Me encanta descubrir nuevas culturas y aprender de cada experiencia.",
        brideDescription2: "Tu presencia hace que este día sea aún más especial. No podría estar más agradecida por compartir esta alegría contigo.",
        
        rsvpData: {
          groomParents: "Padre y Madre Novio",
          brideParents: "Padre y Madre Novia",
          invitationText: "Con la bendición de Dios y la alegría en nuestros corazones, tenemos el honor de invitarte a celebrar nuestra unión matrimonial.",
          invitationText2: "Tu presencia en este día tan especial será un regalo invaluable para nosotros, y nos encantaría compartir este momento lleno de amor y felicidad contigo.",
          invitationText3: "* Agradecemos que la asistencia sea sin niños, para poder compartir este momento íntimo con nuestros seres queridos.",
          brideAndGroom: "Carlos y Jaqueline",
          confirmationDate: "2024-10-26T22:00:00",
          invitationDetails: {
            guestName: guestData.guest_name || "Sr. Nombre Invitado",
            guestCount: guestData.number_of_adults || 1,
            confirmationStatus: null,
            dressCode: "Formal a su elección, evitando la paleta de colores negro y dorado (en vestidos)."
          }
        },

        // Historia de amor
        storyTitle: "Love Story Timeline",
        story: [
          {
            date: 'Octubre 2018',
            title: 'Nuestro Primer Encuentro',
            description: 'Nos conocimos en un ambiente casual, donde las risas fueron instantáneas y la conexión inevitable. Desde esa primera charla, supimos que algo especial estaba por empezar. Compartimos nuestro primer almuerzo en la universidad, marcando el comienzo de nuestra historia.',
            image: image1
          },
          {
            date: 'Marzo 2019',
            title: 'El Inicio de Nuestro Noviazgo',
            description: 'Después de meses conociéndonos y compartiendo momentos únicos, llegó el día en que decidimos dar el siguiente paso. Ese día, entre nervios y sonrisas, formalizamos lo que nuestros corazones ya sabían: estábamos hechos el uno para el otro.',
            image: image2
          },
          {
            date: '20 de Noviembre de 2022',
            title: 'La Propuesta de Matrimonio',
            description: 'Fue un día lleno de sorpresas y emociones. Con cada palabra y cada gesto, el amor que hemos construido brilló más fuerte que nunca. En ese momento, la pregunta fue clara y la respuesta, sin dudarlo, un sí rotundo para un futuro juntos.',
            image: image3
          }
        ],

        // Detalles del evento
        eventTitle: "Time & Place",
        events: [
          {
            title: 'Ceremony',
            time: '5:00 pm',
            date: '24 de diciembre',
            location: 'Santa Catedral de Antigua Guatemala',
            link: 'https://maps.app.goo.gl/93pJRsUVv63JNjre7',
            buttonText: 'Ubicación'
          },
          {
            title: 'Wedding Reception and Party',
            time: '07:00 pm',
            date: '24 de diciembre',
            location: 'Antigua Guatemala',
            link: 'https://maps.app.goo.gl/93pJRsUVv63JNjre7',
            buttonText: 'Ubicación'
          }
        ],

        // GiftTableSection
        giftTitle: "Regalos",
        giftDescription: "Tu presencia será sin duda nuestro mejor regalo. Sin embargo, si deseas aportar en nuestro nuevo comienzo, contaremos con sobres en la boda o si se te facilita más, estaremos dejando nuestro número de cuenta a continuación:",
        giftAccount: "0-0000-00 - Monetaria BI",

        // ItinerarySection
        itineraryTitle: "Itinerary",
        itinerary: [
          { time: "05:00 PM", description: "Ceremonia" },
          { time: "07:00 PM", description: "Recepción" },
          { time: "07:30 PM", description: "Cena" },
          { time: "08:00 PM", description: "Fiesta" },
          { time: "11:50 PM", description: "Salida" }
        ],

        // Galería
        imagesGallery: [imageGallery1, imageGallery2, imageGallery3, imageGallery4, imageGallery5, imageGallery6]
      };

      setData(response);
    } catch (error) {
      console.error("Error fetching guest info:", error);
    }
  };

  useEffect(() => {
    fetchGuestInfo();
  }, [code]);

  if (!data) {
    return <img src={logo} alt="Boda Farias Ibarra" className="logo-overlay" />;
  }

  return (
    <div className="App">
      <audio ref={audioRef} src={backgroundMusic} loop /> {/* Always present audio element */}
      {showContent ? (
        <>
          <Navbar />

          <SectionWrapper>
            <MainImageSection 
              weddingDate={data.weddingDate} 
              weddingName={data.weddingName} 
              weddingMessage={data.weddingMessage} 
            />
          </SectionWrapper>      

          <SectionWrapper>
            <HeroSection weddingDate={data.weddingDate} />
          </SectionWrapper>

          <SectionWrapper>
            <EventsSection eventTitle={data.eventTitle} events={data.events} />
          </SectionWrapper>
          
          <SectionWrapper>
            <ItinerarySection itineraryTitle={data.itineraryTitle} itinerary={data.itinerary} />
          </SectionWrapper>

          <SectionWrapper>
            <RSVPSection guestCode={code} rsvpData={data.rsvpData} fetchGuestInfo={fetchGuestInfo} />
          </SectionWrapper>

          <SectionWrapper>
            <GiftTableSection 
              giftTitle={data.giftTitle} 
              giftDescription={data.giftDescription} 
              giftAccount={data.giftAccount} 
            />
          </SectionWrapper>
          
          <SectionWrapper>
            <CoupleSection
              coupleSectionTitle={data.coupleSectionTitle}
              groomName={data.groomName}
              brideName={data.brideName}
              groomDescription={data.groomDescription}
              groomDescription2={data.groomDescription2}
              brideDescription={data.brideDescription}
              brideDescription2={data.brideDescription2}
              groomImage={groomImage}
              brideImage={brideImage}
              coupleImage={coupleImage}
            />
          </SectionWrapper>

          <SectionWrapper>
            <StorySection storyTitle={data.storyTitle} story={data.story} />
          </SectionWrapper>

          <SectionWrapper>
            <GallerySection images={data.imagesGallery} />
          </SectionWrapper>

          <Footer />

        </>
      ) : (
        <div className="video-container">
          <img src={logoGif} alt="Boda Farias Ibarra" className="logo-overlay" /> {/* Reemplazamos la imagen por el GIF */}
          {!isEnvelopeOpened ? (
            <video
              src={closingVideo}
              autoPlay
              muted
              playsInline
              onClick={handleEnvelopeClick}
              className="video"
              style={{ cursor: 'pointer' }} // Indicates video is clickable
            />
          ) : (
            <video
              ref={openVideoRef}
              src={openingVideo}
              autoPlay
              muted
              playsInline
              onEnded={handleOpenVideoEnd}
              className="video"
            />
          )}
      </div>
      )}
    </div>
  );
};

export default App;

// src/components/CoupleSection.js
import React from 'react';
import './StorySection.css';
import centerImage from '../assets/images/Separator Top.png';

const CoupleSection = ({ storyTitle, story}) => {  

  return (
    <section className="couple" id="story">
      <div className="title-container">
        <img src={centerImage} alt="Flower" className="center-flower-image" />
        <h2>{storyTitle}</h2>
      </div>
      <div className="title-decor">
        <div className="line"></div>
        <div className="circle"></div>
        <div className="line"></div>
      </div>
      <div className="timeline">
        {story.map((event, index) => (
          <div className="timeline-event" key={index}>
            <div className="timeline-content">
              <img src={event.image} alt={event.title} />
              <div className="timeline-info">
                <h3>{event.title}</h3>
                <span>{event.date}</span>
                <p>{event.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CoupleSection;

import React from 'react';
import './Footer.css'; // Import the CSS file
import logo from '../assets/images/Beyond Logo.svg'; // Adjust the path based on your project structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer>
      <img className="footer-image" src={logo} alt="Beyond" />
      <div className="footer-text">
        <p>
          <a href="https://beyond.com.gt" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className="a-icon" icon={faGlobe} /> {/* Globe icon */}
            beyond.com.gt
          </a>
        </p>
        <p>
          <a href="mailto:soporte@beyond.com.gt">
            <FontAwesomeIcon className="a-icon" icon={faEnvelope} /> {/* Envelope icon */}
            soporte@beyond.com.gt
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;

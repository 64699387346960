// src/components/GallerySection.js
import React from 'react';
import './GallerySection.css';

const GallerySection = ({ images }) => {
  return (
    <section className="gallery-section" id="gallery">
      <div className="title-container">
        <h2>Special Moments</h2>
        <div className="title-decor">
          <div className="line"></div>
          <div className="circle"></div>
          <div className="line"></div>
        </div>
      </div>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Galería ${index + 1}`} loading="lazy" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default GallerySection;

// src/components/CoupleSection.js
import React from 'react';
import './CoupleSection.css';

const CoupleSection = ({ coupleSectionTitle, groomName, brideName, groomDescription, groomDescription2, brideDescription, brideDescription2, groomImage, brideImage, coupleImage}) => {
  return (
    <section id="couple" className="couple-section">
      <div className="couple-header">
        <h2>{coupleSectionTitle}</h2>
        <div className="title-decor">
          <div className="line"></div>
          <div className="circle"></div>
          <div className="line"></div>
        </div>
      </div>
      <div className="couple-container">
        <div className="individual">
          <img src={groomImage} alt="Groom" className="profile-image" />
          <h2>{groomName}</h2>
          <h3>El novio</h3>
          <p>{groomDescription}</p>
          <p className="description-message">{groomDescription2}</p>
        </div>
        <div className="couple-image-container">
          <img src={coupleImage} alt="Couple" className="couple-image" />
        </div>
        <div className="individual">
          <img src={brideImage} alt="Bride" className="profile-image" />
          <h2>{brideName}</h2>
          <h3>La novia</h3>
          <p>{brideDescription}</p>
          <p className="description-message">{brideDescription2}</p>
        </div>
      </div>
    </section>
  );
};

export default CoupleSection;

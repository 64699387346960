import React from 'react';
import './EventsSection.css';
import topLeftImage from '../assets/images/top-right-flower.png';

const EventsSection = ({ eventTitle, events }) => {
  return (
    <section className="events-section" id="events">
      <h2>{eventTitle}</h2>
      <div className="title-decor">
        <div className="line"></div>
        <div className="circle"></div>
        <div className="line"></div>
      </div>
      <div className="events-container">
        {events.map((event, index) => (
          <div className="event-card" key={index}>
            <p>{event.date}</p>
            <h3>{event.time}</h3>
            <h4>{event.title}</h4>
            <p><b>{event.location}</b></p>
            <a href={event.link} target="_blank" rel="noopener noreferrer" className="event-button">
              {event.buttonText}
            </a>
          </div>
        ))}
        <img src={topLeftImage} alt="Top Left" className="top-left-image" />
      </div>
    </section>
  );
};

export default EventsSection;

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons'; // Import the copy icon
import './GiftTableSection.css';


const GiftTableSection = ({ giftTitle, giftDescription, giftAccount }) => {
  const cleanAccount = giftAccount.split(' ')[0]; // Get clean account number
  const [copied, setCopied] = useState(false); // Track copy status

  // Function to copy the clean account to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(cleanAccount).then(() => {
      setCopied(true); // Show copied status
      setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
    });
  };

  return (
    <section id="gifts" className="gift-table-section">
      <h2>{giftTitle}</h2>
      <div className="title-decor">
        <div className="line"></div>
        <div className="circle"></div>
        <div className="line"></div>
      </div>
      <p className="gift-description">{giftDescription}</p>
      <p className="gift-account-title">Cuenta a depositar:</p>
      <div className="gift-account-wrapper">
        <p className="gift-account">0-0000-00 Monetaria BI</p>
        <FontAwesomeIcon 
          icon={faCopy} 
          className="copy-icon" 
          onClick={handleCopy} 
          style={{ cursor: 'pointer', marginLeft: '10px' }} 
        />
        {copied && <span className="copy-status">Copiado</span>}
      </div>
    </section>
  );
};

export default GiftTableSection;
